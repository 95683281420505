import Image from './Image';


const ImageArea = (props) => {




    return <Image 
                id={props.data.id}
                styled={props.data.styled} 
                src={props.data.data_url}
            />

}


export default ImageArea