import { useState, useRef } from 'react'
const AreaContainer = (props) => {
    return <header className="cover-header" style={props.styled} id={props.id}> 
        <div
        contentEditable={true}
        className="textareaclass"
        >Enter your text here</div>
    </header>
}

export default AreaContainer