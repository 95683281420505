import { useState , useEffect, useRef } from 'react'
import ImageUploading from 'react-images-uploading';

const ImageAreaToggle = (props) => {
    let titleToolTabRef = useRef(null)
    let titleToggleToolTabRef = useRef(null)
    let contentToolTabRef = useRef(null)
    let contentToolWrapRef = useRef(null)


    const [styleTitle, setStyleTitle] = useState({...props.data.styled})
    const [image, setImage] = useState(props.data.data_url)

    useEffect( () => {
      titleToggleToolTabRef.current.addEventListener('click', toggletTitleToolTab)
    }, [])

    useEffect( () => {
      dataToParent()
    }, [styleTitle])
  

    const toggletTitleToolTab = (e) => {
        if(contentToolTabRef.current.classList.contains('active')){
          contentToolTabRef.current.classList.remove('active')
          contentToolTabRef.current.style.height =   "0px"
        }else{
          let height = contentToolWrapRef.current.clientHeight
          contentToolTabRef.current.classList.add('active')
          contentToolTabRef.current.style.height = height + "px"
        }
    } 




  const handleItem = (e) => {
    let target = e.target
    let itemToChange = target.dataset.itemToChange
    let attributeToChange = target.dataset.attributeToChange
    if(itemToChange == "title"){
      updateTitle(attributeToChange, target.value)
    }
  }

    const updateTitle = (attribute, val) => {


      if(attribute == "backgroundColor"){
        let res = hexToRgb(val)
        setTimeout( () => {
          setStyleTitle(prevState => ({
            ...prevState,
            backgroundColor: "rgba("+res+", "+styleTitle.inputBackgroundOpacity+")",
            inputBackgroundColor:  val
          }))
        }, 30)  
      }

      if(attribute == "backgroundOpacity"){
        let res = val 
        let splitedColor = styleTitle.backgroundColor.split(",");
        setTimeout( () => {
          setStyleTitle(prevState => ({
            ...prevState,
            backgroundColor: `${splitedColor[0]},${splitedColor[1]},${splitedColor[2]},${res})`,
            inputBackgroundOpacity: res
          }))
        }, 30)  
      }

      if(attribute == "translateX"){
        let res = val 
        setTimeout( () => {
          setStyleTitle(prevState => ({
            ...prevState,
            inputPositionX: res,
            transform: `rotate(${styleTitle.rotation}deg) translate(${res}px,${styleTitle.inputPositionY}px)`
          }))
        }, 30)  
      }
      if(attribute == "translateY"){
        let res = val 
        setTimeout( () => {
          setStyleTitle(prevState => ({
            ...prevState,
            inputPositionY: res,
            transform: `rotate(${styleTitle.rotation}deg) translate(${styleTitle.inputPositionX}px,${res}px)`
          }))
        }, 30)  
      }
      if(attribute == "rotation"){
        let res = val 
        setTimeout( () => {
          setStyleTitle(prevState => ({
            ...prevState,
            rotation: res,
            transform: `rotate(${res}deg) translate(${styleTitle.inputPositionX}px,${res}px)`
          }))
        }, 30)  
      }

      if(attribute == "zIndex"){
        let res = val 
        setTimeout( () => {
          setStyleTitle(prevState => ({
            ...prevState,
            zIndex: res
          }))
        }, 30) 
      }

      if(attribute == "width"){
        
        let res = val 
        setTimeout( () => {
          setStyleTitle(prevState => ({
            ...prevState,
            inputWidth: res,
            width: res + "px"
          }))
        }, 30) 
      }

      if(attribute == "height"){
        let res = val 
        setTimeout( () => {
          setStyleTitle(prevState => ({
            ...prevState,
            inputHeight: res,
            height: res + "px"
          }))
        }, 30) 
      }


    }
    const hexToRgb = hex =>
    hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
              ,(m, r, g, b) => '#' + r + r + g + g + b + b)
      .substring(1).match(/.{2}/g)
      .map(x => parseInt(x, 16))


    const handleLayerName = (e)  => {
      e.preventDefault()
      let val = e.target.value
      props.onUpdate({id: props.data.id, title: val, type: "image", data_url: image, styled:  {...styleTitle}})
    }


    const dataToParent =  () => {
      props.onUpdate({id: props.data.id, title: props.data.title, data_url: image, type: "image", styled:  {...styleTitle}})
    } 

    const deleteThisLayer = (e) => {
      e.preventDefault()
      props.onUpdate({id: props.data.id, title: props.data.title, data_url: image, type: "image", styled:  {...styleTitle}, action: "deleteMe"})

    }

    const onImageChange = (imageList, addUpdateIndex) => {
      setTimeout( () => {
        setImage(imageList[0].data_url)
      }, 30) 
    };



    return (<div className="toggle" id={props.data.id}>
    <div className="toggle-title-wrapper" id="toggle-header-for-title" ref={titleToolTabRef} >
      <span className="picto-toggle" ref={titleToggleToolTabRef} onChange={handleLayerName} />
      <span className="toggle-title" contentEditable={true}>{props.data.title}</span>
      <span className="toggle-miniature" style={{ backgroundImage: "url("+props.data.data_url+")" }}></span>
    </div>
    <div className="toggle-content" ref={contentToolTabRef}>
      <div className="toggle-content-wrapper" ref={contentToolWrapRef}>
        <fieldset>
          <label>Import image</label>
          <ImageUploading
        multiple
        value={props.data.data_url}
        onChange={onImageChange}
        maxNumber={1}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <button
              style={isDragging ? { color: 'red' } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
              Click or Drop here
            </button>
          </div>
        )}
      </ImageUploading>
        </fieldset>
        <fieldset>
          <label>Background Color</label>
          <div className="inputs-wrapper">
              <div className="input-explicit">
                <input type="text" min="0" max="50" value={styleTitle.inputBackgroundColor} className="slider" data-item-to-change="title" data-attribute-to-change="backgroundColor" id="background-color" onChange={handleItem} />
              </div>
              <div className="input-implicit">
                <input type="color" min="0" max="50" value={styleTitle.inputBackgroundColor} className="slider" data-item-to-change="title" data-attribute-to-change="backgroundColor" id="background-color" onChange={handleItem} />
              </div>
          </div>
        </fieldset>
        <fieldset>
          <label>Background Opacity</label>
          <div className="inputs-wrapper">
              <div className="input-explicit">
                <input type="number" min="0" max="1" value={styleTitle.inputBackgroundOpacity} step="0.1" className="slider" data-item-to-change="title" data-attribute-to-change="backgroundOpacity" id="background-opacity" onChange={handleItem} />
              </div>
              <div className="input-implicit">
                <input type="range" min="0" max="1" value={styleTitle.inputBackgroundOpacity} step="0.1" className="slider" data-item-to-change="title" data-attribute-to-change="backgroundOpacity" id="background-opacity" onChange={handleItem} />
              </div>
          </div>
        </fieldset>
        <fieldset>
          <label>Position X (px)</label>
          <div className="inputs-wrapper">
              <div className="input-explicit">
                <input type="number" min="-500" max={window.innerWidth/2} value={styleTitle.inputPositionX} step="1" className="slider" data-item-to-change="title" data-attribute-to-change="translateX" id="translateX" onChange={handleItem} />
              </div>
              <div className="input-implicit">
                  <input type="range" min="-500" max={window.innerWidth/2} value={styleTitle.inputPositionX} step="1" className="slider" data-item-to-change="title" data-attribute-to-change="translateX" id="translateX" onChange={handleItem} />
              </div>
          </div>
        </fieldset>
        <fieldset>
          <label>Position y (px)</label>
          <div className="inputs-wrapper">
              <div className="input-explicit">
                <input type="number" min="-500" max={window.innerHeight - styleTitle.inputHeight} value={styleTitle.inputPositionY} step="1" className="slider" data-item-to-change="title" data-attribute-to-change="translateY" id="translateYs" onChange={handleItem} />
              </div>
              <div className="input-implicit">
                <input type="range" min="-500" max={window.innerHeight - styleTitle.inputHeight} value={styleTitle.inputPositionY} step="1" className="slider" data-item-to-change="title" data-attribute-to-change="translateY" id="translateYs" onChange={handleItem} />
              </div>
          </div>
        </fieldset>
        <fieldset>
          <label>Position z</label>
          <div className="inputs-wrapper">
              <div className="input-explicit">
                <input type="number" min="2" max="10" value={styleTitle.zIndex} step="1" className="slider" data-item-to-change="title" data-attribute-to-change="zIndex" id="translateZ" onChange={handleItem} />
              </div>
              <div className="input-implicit">
                <input type="range" min="2" max="10" value={styleTitle.zIndex} step="1" className="slider" data-item-to-change="title" data-attribute-to-change="zIndex" id="translateZ" onChange={handleItem} />
              </div>
          </div>
        </fieldset>
        <fieldset>
          <label>Rotation (deg)</label>
          <div className="inputs-wrapper">
              <div className="input-explicit">
                <input type="number" min="-360" max="360" value={styleTitle.rotation} step="1" className="slider" data-item-to-change="title" data-attribute-to-change="rotation" id="rotation" onChange={handleItem} />
              </div>
              <div className="input-implicit">
                <input type="range" min="-360" max="360" value={styleTitle.rotation} step="1" className="slider" data-item-to-change="title" data-attribute-to-change="rotation" id="rotation" onChange={handleItem} />
              </div>
          </div>
        </fieldset>
        <fieldset>
          <label>Width (px)</label>
          <div className="inputs-wrapper">
              <div className="input-explicit">
                <input type="number" min="0" max={window.innerWidth/2} value={styleTitle.inputWidth} step="1" className="slider" data-item-to-change="title" data-attribute-to-change="width" id="width" onChange={handleItem} />
              </div>
              <div className="input-implicit">
                <input type="range" min="0" max={window.innerWidth/2} value={styleTitle.inputWidth} step="1" className="slider" data-item-to-change="title" data-attribute-to-change="width" id="width" onChange={handleItem} />
              </div>
          </div>
        </fieldset>
        <fieldset>
          <label>Height (px)</label>
          <div className="inputs-wrapper">
              <div className="input-explicit">
                <input type="number" min="20" max={window.innerHeight } value={styleTitle.inputHeight} step="1" className="slider" data-item-to-change="title" data-attribute-to-change="height" id="width" onChange={handleItem} />
              </div>
              <div className="input-implicit">
                <input type="range" min="20" max={window.innerHeight } value={styleTitle.inputHeight} step="1" className="slider" data-item-to-change="title" data-attribute-to-change="height" id="width" onChange={handleItem} />
              </div>
          </div>
        </fieldset>
        <fieldset>
          <label>Delete this layer ?</label>
          <button onClick={deleteThisLayer}>Delete</button>
        </fieldset>
      </div>  
    </div>
</div>)
}

export default ImageAreaToggle