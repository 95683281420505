import React, { useState, useEffect } from "react";
import * as p5 from "p5";

//import  vertex  from './../textures/texture.vert'
//import  frag  from './../textures/texture.frag'

var vert=`
    attribute vec3 aPosition;
    attribute vec2 aTexCoord;
    
    // lets get texcoords just for fun! 
    varying vec2 vTexCoord;
    
    void main() {
      // copy the texcoords
      vTexCoord = aTexCoord;
    
      // copy the position data into a vec4, using 1.0 as the w component
      vec4 positionVec4 = vec4(aPosition, 1.0);
      positionVec4.xy = positionVec4.xy * 2.0 - 1.0;
    
      // send the vertex information on to the fragment shader
      gl_Position = positionVec4;
    }
`;

var frag=`
precision mediump float;

// grab texcoords from vert shader
varying vec2 vTexCoord;

// our textures coming from p5
uniform sampler2D tex0;
uniform sampler2D tex1;
uniform vec2 resolution;
uniform float time;
uniform vec2 mouse;
vec4 tex;

float amt = 0.2; // the amount of displacement, higher is more
float squares = 10.0; // the number of squares to render vertically

void main() {
  float aspect = resolution.x / resolution.y;
  float offset = amt * sin(time) ;

  vec2 uv = vTexCoord;
  
  // the texture is loaded upside down and backwards by default so lets flip it
  uv.y = 1.0 - uv.y;

  // copy of the texture coords
  vec2 tc = uv;

  // move into a range of -0.5 - 0.5
  uv -= 0.5;

  // correct for window aspect to make squares
  uv.x *= aspect;

  // tile will be used to offset the texture coordinates
  // taking the fract will give us repeating patterns
  vec2 tile = fract(uv * squares ) * - amt * mouse.x/100.0;

  // sample the texture using our computed tile
  // offset will remove some texcoord edge artifacting

    tex = texture2D(tex0, tc + tile - offset);


  // render the output
  gl_FragColor = (tex);
}`


const MosaicSketch = props => {
  const imageToLoad = useState('images/skin_b_W.jpg')

  const Sketch = (p5) => {
    let program

    let img
    const inputMosaicImage = document.querySelector("#mosaic_image_miniature")

    p5.preload = () => {
      if(props.image == ""){
        img = p5.loadImage('images/skin_b_W.jpg');
      }else{
        img = p5.loadImage(inputMosaicImage.value)
      }
    };
    p5.setup = () => {
        p5.pixelDensity(1);
      
        let cnv = p5.createCanvas(p5.windowWidth/2, p5.windowHeight  , p5.WEBGL);
        cnv.parent("#canvasWrapper")
        p5.rectMode(p5.CENTER);
        p5.noStroke();
        p5.fill(1);
        program = p5.createShader(vert,frag);

    };

    p5.draw = () => {
        p5.shader(program);
        program.setUniform('tex0', img);
        program.setUniform('resolution', [p5.width, p5.height]);
        program.setUniform('time',p5.millis()/2000.0);
        program.setUniform('mouse',[p5.mouseX, p5.map(p5.mouseY, 0, p5.height, p5.height, 0)]);

        p5.rect(0,0,p5.width,p5.height);
        
    };



    p5.windowResized = () => {
        p5.resizeCanvas(p5.windowWidth/2, p5.windowHeight);
    }
  };

  useEffect(() => {
      console.clear()
      new p5(Sketch);
  });

  return (<></>);
};

export default MosaicSketch;