import Logo from './components/Logo/Logo';
import './App.css';
import { useState, useEffect, useRef } from 'react'
import ImageUploading from 'react-images-uploading';

import TextAreaToggle from './components/Sidebar/Toggle/Simple/TextAreaToggle'
import ImageAreaToggle from './components/Sidebar/Toggle/Image/ImageAreaToggle'
import  SimpleArea from './components/Areas/TextArea/SimpleArea'
import ImageArea from './components/Areas/ImageArea/ImageArea'

import LiquidSketch from './components/P5Animations/LiquidSketch'
import MosaicSketch from './components/P5Animations/MosaicSketch'

import Draggable from 'react-draggable'
import html2canvas from 'html2canvas'
const defaultStyle = {
  paddingTop: "20px", 
  paddingLeft: "20px", 
  paddingRight: "20px",
  paddingBottom: "20px",
  color: "rgba(255, 255, 255, 1)",
  inputTextColorOpacity: 1,
  inputTextColor: "#FFFFFF",
  backgroundColor: "rgba(0,0,0,1)",
  inputBackgroundColor: "#000000",
  inputBackgroundOpacity: 1,
  fontSize: "24px",
  inputFontSize: 24,
  fontFamily: "Imbue",
  inputPositionX: 0,
  inputPositionY: 0,
  transform: "translate(0, 0)",
  zIndex: 2,
  inputWidth: window.innerWidth/4,
  width: window.innerWidth/4,
  inputHeight: window.innerHeight/10,
  height: window.innerHeight/10,
  rotation: 0,
}
function App() {
  const [red, setRed] = useState(1.0)
  const [green, setGreen] = useState(1.0)
  const [blue, setBlue] = useState(1.0)
  const [createdLayer, setCreatedLayers] = useState(0)
  const [draggableSettingInit, setDraggableSettingInit] = useState(0)
  const [currentAnimation, setCurrentAnimation] = useState("liquid")
  const [mosaicImage, setMosaicImage] = useState("")

  let liquidToolTabRef = useRef(null)
  let liquidContentToolRef = useRef(null)
  let liquidContentToolWrapRef = useRef(null)

  const now = Date.now();

  const [customAreas, setCustomAreas] = useState([
    {
      id: "area-"+now,
      title: "Name",
      type: "simple",
      data_url: "",
      styled: {...defaultStyle}
    },
    {
      id: "area-"+now+0.1,
      title: "Layer Name",
      type: "simple",
      styled: {...defaultStyle}
    }
  ])



    useEffect( () => {
      document.querySelector("#toggle-header-for-liquid").addEventListener('click', toggleLiquidToolTab)
      document.onkeyup = (e) => {
        if(e.keyCode == 9){
            captureCover()
        }
      }
    }, [])



  const handleRed = (e) => {
    let val = e.target.value
    setRed(val)
  }

  const handleGreen = (e) => {
    let val = e.target.value
    setGreen(val)
  }

  const handleBlue = (e) => {
    let val = e.target.value
    setBlue(val)
  }



  const toggleLiquidToolTab = () => {
    if(liquidContentToolRef.current.classList.contains('active')){
      liquidContentToolRef.current.classList.remove('active')
      liquidContentToolRef.current.style.height =   "0px"
    }else{
      let height = liquidContentToolWrapRef.current.clientHeight
      liquidContentToolRef.current.classList.add('active')
      liquidContentToolRef.current.style.height = height + "px"
    }
  }

  const captureCover = () => {
   html2canvas(document.querySelector("#canvasWrapper")).then(function(canvas) {
    saveAs(canvas.toDataURL(), 'file-name.png');
  });
  }
  const  saveAs = (uri, filename) => {
    var link = document.createElement('a');
    if (typeof link.download === 'string') {
        link.href = uri;
        link.download = filename;
        //Firefox requires the link to be in the body
        document.body.appendChild(link);
        //simulate click
        link.click();
        //remove the link when done
        document.body.removeChild(link);
    } else {
        window.open(uri);

    }
  }

  const handleToggleUpdate = (val) => {
    let tmpCustomAreas = []
    let tmp = customAreas;

    if(val.action !== null && val.action !== undefined && val.action !== ""){
        // if got some actions registered by the child

        if(val.action === "deleteMe"){ // return all items without this one
          tmpCustomAreas = tmp.filter( (el) => { return el.id !== val.id; } ); 
        }

    }else{
      tmpCustomAreas = tmp.map( (item) => {
        if(val.action === "deleteMa"){
        }else{
          if(item.id !== val.id){ // items not modified yes
            return item 
          }else{ // item to replace
            if(!isEmpty(val)){ 
                return val
            }
          }
        }
      })
    }

    console.log("value from handleToggleUpdate", val)
    setCustomAreas(tmpCustomAreas)
  }

  const isEmpty = (obj) => {
    for(var prop in obj) {
        if(obj.hasOwnProperty(prop))
            return false;
    }
    return true;
  }

  const addNewLayer = (e) => {
    e.preventDefault()
    let newElement = {
      id: "area-ok"+Date.now(),
      title: "Name",
      type: "simple",
      styled: {...defaultStyle}
    }
    setCustomAreas(oldArray => [...customAreas, newElement])
  }

  const addImageLayer = (e) => {
    e.preventDefault()
    let newElement = {
      id: "area-ok"+Date.now(),
      title: "Image layer",
      type: "image",
      styled: {...defaultStyle}
    }
    setCustomAreas(oldArray => [...customAreas, newElement])
  }

  useEffect( () => {
    setCreatedLayers(createdLayer + 1)
  }, [customAreas])

  const handleAnimationChange = (e) => {
    let val = e.target.value

    var parent = document.getElementById("canvasWrapper");
    var cnv = document.querySelector("canvas");

    // Delete child
    parent.removeChild(cnv);
    setTimeout( () => {
      setCurrentAnimation(val)
    }, 200)
  }

  const onImageChange = (imageList, addUpdateIndex) => {
    setTimeout( () => {
      setMosaicImage(imageList[0].data_url)
    }, 30) 
  };


  return (
    <div className="App">
      <div className="logo-wrapper">
        <a href="https://oye.agency">
          <Logo />
        </a>
      </div>
      <Draggable handle="#app-config-area-to-grab">
      <div id="app-config">
        <div id="app-config-area-to-grab" style={{}}>move me</div>
        <form id="settings-form">
          <div className="add-layer-btn-wrapper">
            <button style={{
              width: "50%", 
              height: "auto", 
              color: "black", 
              top: 0, 
              float: "left",
              left: 0,
              width: "95%",
              display: "block"
            }} onClick={addNewLayer}>add layer</button>
            <button style={{
              width: "50%", 
              height: "auto", 
              color: "black", 
              top: 0, 
              float: "left",
              left: 0,
              width: "95%",
              display: "block"
            }} onClick={addImageLayer}>add image</button>
          </div>  
        <div className="toggles-wrapper">
          {
            customAreas.map( (item) => {
              if(item.type === "simple"){
                return <TextAreaToggle data={item} onUpdate={handleToggleUpdate} />
              }
              if(item.type === "image"){
                return <ImageAreaToggle data={item} onUpdate={handleToggleUpdate} />
              }
            })
          }
          <div className="toggle">
              <div className="togglee-title" id="toggle-header-for-liquid" ref={liquidToolTabRef}>
                <span className="picto-toggle" />
                <h3>Animation</h3>
              </div>
              <div className="toggle-content" ref={liquidContentToolRef}>
                <div className="toggle-content-wrapper" ref={liquidContentToolWrapRef}>
                <fieldset>
                  <label>Import image</label>
                  <ImageUploading
        multiple
        value={mosaicImage}
        onChange={onImageChange}
        maxNumber={1}
        dataURLKey="data_url"
        id="mosaic_upload_image"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <input id="mosaic_image_miniature" type="text" value={mosaicImage} />
            <button
              style={isDragging ? { color: 'red' } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
              Click or Drop here
            </button>
          </div>
        )}
      </ImageUploading>
                </fieldset>  
                <fieldset>
                  <label>Choose your animation</label>
                  <select onChange={handleAnimationChange}>
                    <option value="liquid">Liquid</option>
                    <option value="mosaicture">Mosaicture</option>
                  </select>
                </fieldset>
                <fieldset>
                  <label>Red</label>
                  <input type="range" min="0.0" max="1.0"  step="0.01" className="slider" id="red-range"  />
                </fieldset>
                <fieldset>
                  <label>Green</label>
                  <input type="range" min="0.0" max="1.0"  step="0.01" className="slider" id="green-range"  />
                </fieldset>
                <fieldset>
                  <label>Blue</label>
                  <input type="range" min="0.0" max="1.0"  step="0.01" className="slider" id="blue-range" />
                </fieldset>
                </div>  
              </div>
          </div>
        </div>
        </form>
        <div>
          <h2>Press TAB to save your exploration</h2>
        </div>
      </div>
      </Draggable>
      <div id="canvasWrapper">
        {
          customAreas.map( (item) => {
            if(item.type === "simple"){
              return <SimpleArea data={item} />
            }
            if(item.type === "image"){
              return <ImageArea data={item} />
            }
          })
        }
        {
          currentAnimation === "liquid" ? <LiquidSketch red={red} green={green} blue={blue} /> : ""
        }

        {
          currentAnimation === "mosaicture" ? <MosaicSketch red={red} green={green} blue={blue} image={mosaicImage} /> : ""
        }
        
      </div>
    </div>
  );
}

export default App;
