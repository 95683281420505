import React, { useEffect } from "react";
import * as p5 from "p5";
//import  vertex  from './../textures/texture.vert'
//import  frag  from './../textures/texture.frag'
import {useState} from 'react'
var vert=`
#ifdef GL_ES
      precision highp float;
      precision highp int;
    #endif
		#extension GL_OES_standard_derivatives : enable

    // attributes, in
    attribute vec3 aPosition;
    attribute vec3 aNormal;
    attribute vec2 aTexCoord;
    attribute vec4 aVertexColor;

    // attributes, out
    varying vec3 var_vertPos;
    varying vec4 var_vertCol;
    varying vec3 var_vertNormal;
    varying vec2 var_vertTexCoord;

    // matrices
    uniform mat4 uModelViewMatrix;
    uniform mat4 uProjectionMatrix;
    //uniform mat3 uNormalMatrix;

    void main() {
      gl_Position = uProjectionMatrix * uModelViewMatrix * vec4(aPosition, 1.0);

      // just passing things through
      // var_vertPos      = aPosition;
      // var_vertCol      = aVertexColor;
      // var_vertNormal   = aNormal;
      // var_vertTexCoord = aTexCoord;
    }
`;

var frag=`

#ifdef GL_ES
precision mediump float;
#endif

uniform vec2 resolution;
uniform float time;
uniform vec2 mouse;
uniform float bluedivision;
uniform float greendivision;
uniform float reddivision;
const int AMOUNT = 12;

void main(){
	vec2 coord = (5.0 * (mouse.x / 1000.0) ) * (gl_FragCoord.xy - resolution / 2.0) / min(resolution.y, resolution.x);

	float len;

	for (int i = 0; i < AMOUNT; i++){
		len = length(vec2(coord.x, coord.y));

		coord.x = coord.x - cos(coord.y + sin(len)) + cos(time / 9.0);
		coord.y = coord.y + sin(coord.x + cos(len)) + sin(time / 12.0);
	}

	gl_FragColor = vec4(cos(len / reddivision), cos(len / greendivision ), cos(len / bluedivision), 1.0);


}`


const LiquidSketch = props => {

  const Sketch = (p5) => {
    let rectShader;
    let cnv
    let program
    let inconsolata
    let red  = 1.0
    let green  = 1.0
    let blue = 1.0


    p5.preload = () => {


    };
    p5.setup = () => {
        p5.pixelDensity(1);
        //createCanvas(500, 500,WEBGL);
      
        let cnv = p5.createCanvas(p5.windowWidth/2, p5.windowHeight  , p5.WEBGL);
        cnv.parent("#canvasWrapper")
        //gl=this.canvas.getContext('webgl');
        p5.rectMode(p5.CENTER);
        p5.noStroke();
        p5.fill(1);
        program = p5.createShader(vert,frag);
    };

    p5.draw = () => {
        p5.shader(program);
        p5.background(0);
        program.setUniform('resolution',[p5.width,p5.height]);
        program.setUniform('time',p5.millis()/2000.0);
        program.setUniform('mouse',[p5.mouseX, p5.map(p5.mouseY, 0, p5.height, p5.height, 0)]);
        program.setUniform('reddivision', red / 10 )
        program.setUniform('greendivision', green / 10)
        program.setUniform('bluedivision', blue / 10)
        p5.rect(0,0,p5.width,p5.height);
    };

    document.querySelector("#red-range").addEventListener("change", (e) => {
        red = e.target.value
    })
    document.querySelector("#green-range").addEventListener("change", (e) => {
        green = e.target.value
    })
    document.querySelector("#blue-range").addEventListener("change", (e) => {
        blue = e.target.value
    })




    p5.windowResized = () => {
        p5.resizeCanvas(p5.windowWidth/2, p5.windowHeight);
    }
  };

  useEffect(() => {
      console.clear()
    new p5(Sketch);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (<></>);
};

export default LiquidSketch;