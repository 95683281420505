import AreaContainer from './AreaContainer';


const SimpleArea = (props) => {




    return <AreaContainer 
                id={props.data.id}
                styled={props.data.styled} 
            />

}


export default SimpleArea